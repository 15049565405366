import Cookie from 'js-cookie';

window.addEventListener('load', () => {
  const $ = window.$;
  const slideTime = 200;
  let slideUpTimer;
  const dropdownBtn = $('#dropdown-toggle');
  const dropdownMenu = $('#dropdown-menu');

  $('.header-btn').on('click', () => {
    if ($('.search .input-wrap').is(':hidden')) {
      $('.search .input-wrap').slideDown(200);
    } else {
      $('.search .input-wrap').slideUp(200);
    }
  });
  const localeBtn = $('.locale');
  localeBtn.on('click', () => {
    let locale = 'zh-CN';
    if (localeBtn.hasClass('en')) {
      locale = 'en-US';
    }
    Cookie.set('locale', locale);
    window.location.reload();
  });

  $('.search-btn').on('click', () => {
    const seach = $('.search-text').val();
    window.location.href = `product?page=1&search=${seach}`;
  });

  if (dropdownBtn) {
    dropdownBtn.on('mouseenter', () => {
      console.log(111);
      dropdownMenu.slideDown(slideTime);
    });
    dropdownBtn.on('mouseleave', () => {
      slideUpTimer = setTimeout(() => {
        dropdownMenu.slideUp(slideTime);
      }, 300);
    });
    dropdownMenu.on('mouseenter', () => {
      clearTimeout(slideUpTimer);
    });
    dropdownMenu.on('mouseleave', () => {
      slideUpTimer = setTimeout(() => {
        dropdownMenu.slideUp(slideTime);
      }, 300);
    });
  }
});
